var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"py-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',{staticClass:"pb-2"},[_c('v-form',{ref:"form-edit-usuario-permisos",attrs:{"form":"form-edit-usuario-permisos","id":"form-edit-usuario-permisos"},on:{"submit":function($event){$event.preventDefault();return _vm.validarUsuarioHabilitado()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"d-flex justify-center pb-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Nombre","outlined":"","rules":_vm.rules.required.concat(
                [_vm.rules.maxLength(_vm.nombre, 50)],
                [_vm.rules.requiredTrim(_vm.nombre)]
              )},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1),_c('v-col',{staticClass:"pb-0 pr-0",attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Email","dense":"","outlined":"","rules":_vm.rules.required.concat([
                _vm.rules.requiredTrim(_vm.userEmail),
                _vm.rules.maxLength(_vm.userEmail, 100),
                function (v) { return _vm.rules.noSpecialCharacters[0](v); } ])},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"userEmail"}})],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"2"}},[_c('p',{staticClass:"py-0 mt-2"},[_c('strong',[_vm._v("@macena.com.ar")])])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","label":"Usuario","outlined":"","rules":_vm.rules.required.concat(
                [_vm.rules.maxLength(_vm.nombre, 50)],
                [_vm.rules.requiredTrim(_vm.nombre)]
              )},model:{value:(_vm.userNom),callback:function ($$v) {_vm.userNom=$$v},expression:"userNom"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],staticClass:"right-input",attrs:{"dense":"","label":"Vig. contraseña","suffix":"días","outlined":"","rules":_vm.rules.required.concat([
                function (v) { return _vm.rules.positiveNumberHigherThanCero[0](v); } ])},model:{value:(_vm.diasCambioContraseña),callback:function ($$v) {_vm.diasCambioContraseña=$$v},expression:"diasCambioContraseña"}})],1),(!this.usuarioEditItem)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"tabindex":"2","outlined":"","dense":"","label":"Contraseña","append-icon":_vm.userPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.textType ? 'password' : 'text',"rules":_vm.rules.password},on:{"click:append":function () { return (_vm.textType = !_vm.textType); },"focus":function($event){_vm.inputActive = 'userPassword'},"blur":function($event){_vm.inputActive = ''}},model:{value:(_vm.userPassword),callback:function ($$v) {_vm.userPassword=$$v},expression:"userPassword"}})],1):_vm._e(),(this.usuarioEditItem)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"5"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Usuario Deshabilitado"},model:{value:(_vm.usuarioDeshabilitado),callback:function ($$v) {_vm.usuarioDeshabilitado=$$v},expression:"usuarioDeshabilitado"}})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.grupos,"item-text":"userGroupNom","item-value":"userGroupId","label":"Grupo de usuario al que pertenece","outlined":"","autocomplete":"off","clearable":"","dense":"","return-object":"","multiple":"","rules":_vm.rules.required},model:{value:(_vm.userGroupSelected),callback:function ($$v) {_vm.userGroupSelected=$$v},expression:"userGroupSelected"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":!_vm.isFormValid,"form":"form-edit-usuario-permisos"}},[_vm._v(" Guardar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }