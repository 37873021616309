<template>
  <v-dialog v-model="openDialog" max-width="45%" persistent>
    <v-card>
      <v-card-title class="headline primary--text">{{ title }}</v-card-title>
      <v-card-text class="text-left pb-0">
        <v-form
          v-model="isFormValid"
          ref="change-password-form"
          form="change-password-form"
          id="change-password-form"
          @submit.prevent="confirm()">
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                outlined
                dense
                label="Nueva contraseña"
                :append-icon="genericPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (textType = !textType)"
                :type="textType ? 'password' : 'text'"
                :rules="
                  rules.password.concat(
                    repeatGenericPassword != null
                      ? [
                          rules.samePassword(
                            genericPassword,
                            repeatGenericPassword
                          ),
                        ]
                      : []
                  )
                "
                v-model="genericPassword"
                @focus="inputActive = 'genericPassword'"
                @blur="inputActive = ''"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-text-field
                outlined
                dense
                label="Repetir contraseña"
                :append-icon="repeatGenericPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (textType = !textType)"
                :type="textType ? 'password' : 'text'"
                :rules="
                  repeatGenericPassword != null
                    ? rules.required.concat([
                        rules.samePassword(
                          genericPassword,
                          repeatGenericPassword
                        ),
                      ])
                    : rules.required
                "
                v-model="repeatGenericPassword"
                @focus="inputActive = 'repeatGenericPassword'"
                @blur="inputActive = ''"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0 pr-6">
        <v-spacer></v-spacer>
        <v-btn outlined @click="cerrar">Cancelar</v-btn>
        <v-btn
          color="primary"
          type="submit"
          :loading="isLoading"
          :disabled="!isFormValid"
          form="change-password-form"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";

export default {
  name: "ChangeAllUsersPassword",
  props: {
    openDialog: { type: Boolean, required: true },
    isLoading: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      title: enums.titles.CHANGE_ALL_USERS_PASSWORD,
      rules: rules,
      genericPassword: null,
      repeatGenericPassword: null,
      textType: String,
      isFormValid: false,
    };
  },
  methods: {
    confirm() {
      this.$emit("confirmChange", this.genericPassword);
    },
    cerrar() {
      location.reload();
    },
  },
};
</script>

<style></style>
