<template>
  <v-card outlined>
    <v-card-title>
      <span class="py-1 primary--text">{{ formEditTitle }}</span>
    </v-card-title>
    <v-card-text class="pb-2">
      <v-form
        v-model="isFormValid"
        ref="form-edit-usuario-permisos"
        form="form-edit-usuario-permisos"
        id="form-edit-usuario-permisos"
        @submit.prevent="validarUsuarioHabilitado()">
        <v-row class="d-flex justify-center pb-0">
          <v-col cols="6" class="pb-0">
            <v-text-field
              v-model="nombre"
              dense
              label="Nombre"
              outlined
              :rules="
                rules.required.concat(
                  [rules.maxLength(nombre, 50)],
                  [rules.requiredTrim(nombre)]
                )
              ">
            </v-text-field>
          </v-col>

          <v-col cols="4" sm="4" md="4" class="pb-0 pr-0">
            <v-text-field
              v-model.trim="userEmail"
              label="Email"
              dense
              outlined
              :rules="
                rules.required.concat([
                  rules.requiredTrim(userEmail),
                  rules.maxLength(userEmail, 100),
                  (v) => rules.noSpecialCharacters[0](v),
                ])
              ">
            </v-text-field>
          </v-col>
          <v-col cols="2" class="px-0">
            <p class="py-0 mt-2"><strong>@macena.com.ar</strong></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" class="pb-0">
            <v-text-field
              v-model="userNom"
              dense
              label="Usuario"
              outlined
              :rules="
                rules.required.concat(
                  [rules.maxLength(nombre, 50)],
                  [rules.requiredTrim(nombre)]
                )
              ">
            </v-text-field>
          </v-col>

          <v-col cols="2" class="pb-0">
            <v-text-field
              v-model="diasCambioContraseña"
              dense
              label="Vig. contraseña"
              suffix="días"
              class="right-input"
              v-mask="'####'"
              outlined
              :rules="
                rules.required.concat([
                  (v) => rules.positiveNumberHigherThanCero[0](v),
                ])
              "></v-text-field>
          </v-col>
          <v-col cols="5" class="pb-0" v-if="!this.usuarioEditItem">
            <v-text-field
              tabindex="2"
              outlined
              dense
              label="Contraseña"
              :append-icon="userPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (textType = !textType)"
              :type="textType ? 'password' : 'text'"
              :rules="rules.password"
              v-model="userPassword"
              @focus="inputActive = 'userPassword'"
              @blur="inputActive = ''"></v-text-field>
          </v-col>
          <v-col cols="5" class="pb-0" v-if="this.usuarioEditItem">
            <v-switch
              class="mt-0"
              v-model="usuarioDeshabilitado"
              label="Usuario Deshabilitado"></v-switch>
          </v-col>
          <v-col cols="12" md="12" class="py-0">
            <v-autocomplete
              v-model="userGroupSelected"
              :items="grupos"
              item-text="userGroupNom"
              item-value="userGroupId"
              label="Grupo de usuario al que pertenece"
              outlined
              autocomplete="off"
              clearable
              dense
              return-object
              multiple
              :rules="rules.required">
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeModal"> Cancelar </v-btn>
      <v-btn color="primary" type="submit" :disabled="!isFormValid" form="form-edit-usuario-permisos">
        Guardar
      </v-btn>
    </v-card-actions>
    <!--
    <v-dialog
      v-model="modalResetPass"
      v-if="modalResetPass"
      @keydown.esc="closeModalResetPass"
      max-width="40%"
      persistent>
      <v-card>
        <v-card-title class="headline primary--text"
          ><span>Advertencia</span></v-card-title
        >
        <v-card-text class="text-left"
          ><span
            ><strong
              >Al guardar el usuario en estado: "Deshabilitado" se reiniciaran
              las contraseñas de todos los usuarios ¿Desea continuar?
            </strong></span
          ></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeModalResetPass" :loading="loadingSaveBtn"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="primary"
            dark
            @click="resetPass"
            :loading="loadingSaveBtn"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>-->
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  name: "EditUsuarioYPermisos",
  props: {
    usuarioEditItem: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      expanded: [],
      time: null,
      isFormValid: false,
      rules: rules,
      usuarios: [],
      formEditTitle: "Editar usuario",
      loadingSaveBtn: false,
      search: "",
      dialogDelete: false,
      routeToGo: "/",
      title: "Usuarios y permisos",
      userId: null,
      userNom: null,
      userEmail: null,
      nombre: null,
      userGroupSelected: [],
      userPassword: null,
      textType: String,
      openModalEdit: false,
      userGroups: [],
      grupos: [],
      usuarioDeshabilitado: false,
      diasCambioContraseña: 30,
      grupoById: {},
      // modalResetPass: false,
    };
  },
  created() {
    this.loadGrupos();
    if (this.usuarioEditItem) {
      this.setUsuario();
    } else {
      this.formEditTitle = "Nuevo usuario";
    }
  },
  methods: {
    ...mapActions({
      getGruposUsuario: "usuarios/getGruposUsuario",
      getUsuariosYPerm: "usuarios/getUsuariosYPerm",
      saveUsuario: "usuarios/saveUsuario",
      setAlert: "user/setAlert",
    }),

    async loadGrupos() {
      this.isLoading = true;
      const grupos = await this.getGruposUsuario();
      this.grupos = grupos;
      this.isLoading = false;
    },
    async setUsuario() {
      this.userId = this.usuarioEditItem.userId;
      const gru = await this.getUsuariosYPerm(this.usuarioEditItem.userId);
      this.grupoById = gru[0];
      this.nombre = this.grupoById.nombre;
      this.userNom = this.grupoById.userNom;
      this.userPassword = this.grupoById.userPassword;
      this.userEmail = this.grupoById.userEmail;
      this.userGroupSelected = this.grupoById.userGroups;
      this.diasCambioContraseña = this.grupoById.diasCambioContraseña;
      this.usuarioDeshabilitado = this.grupoById.usuDeshabilitado;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    validarUsuarioHabilitado() {
      this.save();
      // if (this.usuarioDeshabilitado) {
      //   this.modalResetPass = true;
      // } else {
        // this.save();
      // }
    },
    // closeModalResetPass() {
    //   this.modalResetPass = false;
    // },
    resetPass() {
      this.save();
      this.$emit("confirmDeshabilitar");
    },
    async save() {
      let payload = {
        userId: this.userId ? this.userId : 0,
        userNom: this.userNom,
        nombre: this.nombre,
        userPassword: this.userPassword,
        userEmail: this.userEmail,
        userGroups: this.userGroupSelected,
        diasCambioContraseña: this.diasCambioContraseña,
        Deshabilitar: this.usuarioDeshabilitado
          ? this.usuarioDeshabilitado
          : false,
      };
      const res = await this.saveUsuario(payload);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
      }
      this.closeModal();
    },
  },
};
</script>
<style scoped>
::v-deep .myLabel {
  color: white;
}
.to-right {
  float: right;
}
.right-input >>> input {
  text-align: right;
}
</style>
